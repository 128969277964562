/* @import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap'); */








/*  
  body {  
    font-family:'Poppins', Arial, sans-serif;
    font-weight:normal;
  }  
body { 
    font-family: 'Poppins', Arial, sans-serif; 
    font-weight: normal; 
    margin: 0;
} 

body { 
    min-height: 100%;
} 

body { 
    padding-top: var(--headerHeight, 0px);
} 

html { 
    font-family: 'Poppins', Arial, sans-serif; 
    font-weight: normal; 
    margin: 0;
} 

html { 
    min-height: 100%;
}  */

:root {
    --color-base000: 0, 0, 0;
    --color-base200: 50, 50, 50;
    --color-base600: 150, 150, 150;
    --color-base750: 190, 190, 190;
    --color-base800: 210, 210, 210;
    --color-base950: 246, 246, 246;
    --color-base1000: 255, 255, 255;
    --color-primary-dark: 0, 92, 191;
    --color-primary: 0, 111, 230;
    --color-primary-light: 0, 158, 255;
    --color-primary-lightest: 204, 226, 250;
    --color-error: 246, 36, 71;
    --color-success: 101, 199, 40;
    --color-shadow: var(--color-base000);
    --color-overlay: var(--color-base200);
    --color-interface: var(--color-base1000);
    --color-interface-secondary: var(--color-base950);
    --color-interface-highlight: var(--color-base1000);
    --color-interface-headerfooter: var(--color-base1000);
    --color-border: var(--color-base800);
    --color-disabled: var(--color-base600);
    --color-disabled-contrast: var(--color-base1000);
    --color-font: var(--color-base200);
    --color-font-contrast: var(--color-base1000);
    --color-font-secondary: var(--color-base600);
    --color-font-disabled: var(--color-base600);
    --color-appBar-bg: var(--color-interface-secondary);
    --color-appBar-color: var(--color-font);
    --color-appBar-hover: var(--color-primary);
    --color-appBar--active-bg: var(--color-interface);
    --color-appBar--active-color: var(--color-primary);
    --color-appBar--active-indicator: var(--color-primary);
    --color-appBar--badge: var(--color-error);
    --color-appHeader-bg: var(--color-interface-secondary);
    --color-appHeader-font: var(--color-primary);
}

:root {
    --headerHeight: 144px;
    --headerHeightScroll: 92px;
}

/* 
a:hover { 
    color: #00bfa5;
}  */

.stepper__steps {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
}

.stepper {
    display: flex;
    flex-direction: column;
}

.rendered-snippet {
    background-color: #fff;
    padding: 12px 8px 12px 8px;
    position: relative;
}

.stepper__steps .step {
    align-items: center;
    color: rgba(var(--color-font), 1);
    display: flex;
    flex-direction: column;
    gap: unset;
    justify-content: space-between;
    text-decoration: none;
    min-width: 48px;
    width: 100%;
}

.stepper__steps .step__status {
    align-items: center;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-start;
    min-height: 64px;
    position: relative;
    /* transition: color .2s ease-in-out, background-color .2s ease-in-out; */
    height: 56px;
    width: 100%;
}
.step:not(.is-disabled) .step__status{
    cursor: pointer;
}
.stepper__steps .step__status div, .stepper__steps .step__status a{
    transition: color .2s ease-in-out, background-color .2s ease-in-out;

}
 .stepper__steps .step__status:hover {
    background-color: rgba(var(--color-primary-lightest), 0.4);
    color: rgba(var(--color-primary-dark), 1);
}
/* 
.stepper__steps .step.is-current .step__status {
    pointer-events: none;
} */

.stepper__steps .step__flyout__container {
    display: flex;
    gap: 8px;
    width: 100%;
}

 .stepper__steps .step.is-disabled .step__status:hover {
    background: none;
}

 .stepper__steps .step.is-disabled .step__status:hover,
 .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled .step.button .step__status:hover,
 .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps .step.button .step__status:hover,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled button.step .step__status:hover,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps button.step .step__status:hover,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="button"] .step__status:hover,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="button"] .step__status:hover,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="reset"] .step__status:hover,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="reset"] .step__status:hover,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="submit"] .step__status:hover,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="submit"] .step__status:hover,
 .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled .pager__page a.step .step__status:hover,
 .dropzone__container.dragAndDrop__area.is-disabled .pager__page .stepper__steps a.step .step__status:hover,
 .stepper__steps .pager__page .dropzone__container.dragAndDrop__area.is-disabled a.step .step__status:hover,
 .pager__page .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps a.step .step__status:hover,
 .stepper__steps .step.has-error.is-disabled .step__status:hover,
 .stepper__steps .step.is-finished.is-disabled .step__status:hover {
    background: none;
}

.stepper__steps .step__status__indicator {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    height: 100%;
}

/* 
a:visited { 
    color: #009688;
}  */

.stepper__steps .step__status__label {
    box-sizing: border-box;
    color: rgba(var(--color-font), 1);
    font-size: clamp(.98rem, .97rem + .07vw, 1.05rem);
    font-weight: bold;
    line-height: 1.32em;
    overflow: hidden;
    padding: 0;
    text-align: left;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

 .stepper__steps .step__status__label:visited {
    color: rgba(var(--color-standard), 1);
}

.stepper__steps .step.is-finished .step__status__label {
    color: rgba(var(--color-success), 1);
}

 .stepper__steps .step.is-finished .step__status__label:visited {
    color: rgba(var(--color-success), 1);
}

 .stepper__steps .step__status__label::before {
    bottom: 2px;
    border-radius: 3px;
    /* content: ""; */
    display: block;
    left: 2px;
    position: absolute;
    right: 2px;
    top: 2px;
}

 .stepper__steps .step.is-finished .step__status:hover .step__status__label {
    color: rgba(var(--color-primary-dark), 1);
}

 .stepper__steps .step.is-finished .step__status:hover .step__status__label,
 .stepper__steps .step.has-error .step__status:hover .step__status__label {
    color: rgba(var(--color-primary-dark), 1);
}

.stepper__steps .step.is-current .step__status__label {
    color: rgba(var(--color-primary-dark), 1);
}

 .stepper__steps .step.is-current .step__status__label:visited {
    color: rgba(var(--color-primary-dark), 1);
}

.stepper__steps .step__status__line__wrapper {
    height: 100%;
    width: auto;
}

.stepper__steps .step__flyout__container .step__status__line__wrapper {
    display: flex;
    justify-content: center;
    min-width: 32px;
    padding-left: 16px;
    position: relative;
    visibility: visible;
    height: auto;
    box-sizing: content-box;
}

.stepper__steps .step__flyout {
    width: 100%;
}

.stepper__steps .step.is-disabled .step__status__label {
    color: rgba(var(--color-base750), 1);
}

 .stepper__steps .step.is-disabled .step__status__label:visited {
    color: rgba(var(--color-base750), 1);
}

 .stepper__steps .step.is-disabled .step__status__label:visited,
 .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled .step.button .step__status__label:visited,
 .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps .step.button .step__status__label:visited,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled button.step .step__status__label:visited,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps button.step .step__status__label:visited,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="button"] .step__status__label:visited,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="button"] .step__status__label:visited,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="reset"] .step__status__label:visited,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="reset"] .step__status__label:visited,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="submit"] .step__status__label:visited,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="submit"] .step__status__label:visited,
 .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled .pager__page a.step .step__status__label:visited,
 .dropzone__container.dragAndDrop__area.is-disabled .pager__page .stepper__steps a.step .step__status__label:visited,
 .stepper__steps .pager__page .dropzone__container.dragAndDrop__area.is-disabled a.step .step__status__label:visited,
 .pager__page .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps a.step .step__status__label:visited,
 .stepper__steps .step.has-error.is-disabled .step__status__label:visited,
 .stepper__steps .step.is-finished.is-disabled .step__status__label:visited {
    color: rgba(var(--color-base750), 1);
}

 .stepper__steps .step.is-disabled .step__status__label::before,
 .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled .step.button .step__status__label::before,
 .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps .step.button .step__status__label::before,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled button.step .step__status__label::before,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps button.step .step__status__label::before,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="button"] .step__status__label::before,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="button"] .step__status__label::before,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="reset"] .step__status__label::before,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="reset"] .step__status__label::before,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="submit"] .step__status__label::before,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="submit"] .step__status__label::before,
 .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled .pager__page a.step .step__status__label::before,
 .dropzone__container.dragAndDrop__area.is-disabled .pager__page .stepper__steps a.step .step__status__label::before,
 .stepper__steps .pager__page .dropzone__container.dragAndDrop__area.is-disabled a.step .step__status__label::before,
 .pager__page .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps a.step .step__status__label::before,
 .stepper__steps .step.has-error.is-disabled .step__status__label::before,
 .stepper__steps .step.is-finished.is-disabled .step__status__label::before {
    cursor: not-allowed;
}

 .stepper__steps .step.is-disabled .step__status:hover .step__status__label {
    color: rgba(var(--color-base750), 1);
}

 .stepper__steps .step.is-disabled .step__status:hover .step__status__label,
 .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled .step.button .step__status:hover .step__status__label,
 .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps .step.button .step__status:hover .step__status__label,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled button.step .step__status:hover .step__status__label,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps button.step .step__status:hover .step__status__label,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="button"] .step__status:hover .step__status__label,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="button"] .step__status:hover .step__status__label,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="reset"] .step__status:hover .step__status__label,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="reset"] .step__status:hover .step__status__label,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="submit"] .step__status:hover .step__status__label,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="submit"] .step__status:hover .step__status__label,
 .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled .pager__page a.step .step__status:hover .step__status__label,
 .dropzone__container.dragAndDrop__area.is-disabled .pager__page .stepper__steps a.step .step__status:hover .step__status__label,
 .stepper__steps .pager__page .dropzone__container.dragAndDrop__area.is-disabled a.step .step__status:hover .step__status__label,
 .pager__page .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps a.step .step__status:hover .step__status__label,
 .stepper__steps .step.has-error.is-disabled .step__status:hover .step__status__label,
 .stepper__steps .step.is-finished.is-disabled .step__status:hover .step__status__label {
    color: rgba(var(--color-base750), 1);
}

.stepper__steps .step.has-error .step__status__label {
    color: rgba(var(--color-error), 1);
}

 .stepper__steps .step.has-error .step__status__label:visited {
    color: rgba(var(--color-error), 1);
}

.stepper__steps .step:first-child .step__status__indicator .step__status__line__wrapper:first-child {
    visibility: hidden;
}

.stepper__steps .step__status__dot {
    align-items: center;
    border: 2px solid rgba(var(--color-base600), 1);
    box-sizing: border-box;
    border-radius: 100%;
    display: flex;
    font-size: 20px;
    justify-content: center;
    min-height: 32px;
    min-width: 32px;
}

.stepper__steps .step.is-finished .step__status__dot {
    border-color: rgba(var(--color-success), 1);
    color: rgba(var(--color-success), 1);
    margin-top: -1px;
    margin-bottom: -1px;
}

 .stepper__steps .step.is-finished .step__status__dot::before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    speak: none;
    text-decoration: none;
    text-transform: none;
    /* font-family: 'Poppins' !important;  */
    /* content: ""; */
}

 .stepper__steps .step__status:hover .step__status__dot {
    border-color: rgba(var(--color-primary-dark), 1);
}

 .stepper__steps .step.is-finished .step__status:hover .step__status__dot {
    border-color: rgba(var(--color-primary-dark), 1);
    color: rgba(var(--color-primary-dark), 1);
}

 .stepper__steps .step.is-finished .step__status:hover .step__status__dot,
 .stepper__steps .step.has-error .step__status:hover .step__status__dot {
    border-color: rgba(var(--color-primary-dark), 1);
    color: rgba(var(--color-primary-dark), 1);
}

.stepper__steps .step.is-current .step__status__dot {
    border-color: rgba(var(--color-primary), 1);
}

 .stepper__steps .step.is-current .step__status__dot::before {
    background-color: rgba(var(--color-primary), 1);
    border-radius: 100%;
    /* content: ""; */
    height: 8px;
    width: 8px;
}

.stepper__steps .step__status__line {
    background-color: rgba(var(--color-base600), 1);
    height: 100%;
    width: 2px;
}

.stepper__steps .step.is-current .step__status__line {
    background-color: rgba(var(--color-primary), 1);
}

.stepper__steps .step__flyout__footer {
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    margin-left: -40px;
    margin-top: 0;
    padding: 8px 16px 8px 40px;
}

.stepper__steps .step.is-disabled .step__status__dot {
    border-color: rgba(var(--color-base750), 1);
    color: rgba(var(--color-base750), 1);
}

 .stepper__steps .step.is-disabled .step__status__dot::before,
 .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled .step.button .step__status__dot::before,
 .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps .step.button .step__status__dot::before,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled button.step .step__status__dot::before,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps button.step .step__status__dot::before,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="button"] .step__status__dot::before,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="button"] .step__status__dot::before,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="reset"] .step__status__dot::before,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="reset"] .step__status__dot::before,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="submit"] .step__status__dot::before,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="submit"] .step__status__dot::before,
 .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled .pager__page a.step .step__status__dot::before,
 .dropzone__container.dragAndDrop__area.is-disabled .pager__page .stepper__steps a.step .step__status__dot::before,
 .stepper__steps .pager__page .dropzone__container.dragAndDrop__area.is-disabled a.step .step__status__dot::before,
 .pager__page .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps a.step .step__status__dot::before,
 .stepper__steps .step.has-error.is-disabled .step__status__dot::before,
 .stepper__steps .step.is-finished.is-disabled .step__status__dot::before {
    background-color: rgba(var(--color-base750), 1);
}

 .stepper__steps .step.is-disabled .step__status:hover .step__status__dot {
    border-color: rgba(var(--color-base750), 1);
    color: rgba(var(--color-base750), 1);
}

 .stepper__steps .step.is-disabled .step__status:hover .step__status__dot,
 .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled .step.button .step__status:hover .step__status__dot,
 .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps .step.button .step__status:hover .step__status__dot,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled button.step .step__status:hover .step__status__dot,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps button.step .step__status:hover .step__status__dot,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="button"] .step__status:hover .step__status__dot,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="button"] .step__status:hover .step__status__dot,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="reset"] .step__status:hover .step__status__dot,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="reset"] .step__status:hover .step__status__dot,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="submit"] .step__status:hover .step__status__dot,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="submit"] .step__status:hover .step__status__dot,
 .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled .pager__page a.step .step__status:hover .step__status__dot,
 .dropzone__container.dragAndDrop__area.is-disabled .pager__page .stepper__steps a.step .step__status:hover .step__status__dot,
 .stepper__steps .pager__page .dropzone__container.dragAndDrop__area.is-disabled a.step .step__status:hover .step__status__dot,
 .pager__page .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps a.step .step__status:hover .step__status__dot,
 .stepper__steps .step.has-error.is-disabled .step__status:hover .step__status__dot,
 .stepper__steps .step.is-finished.is-disabled .step__status:hover .step__status__dot {
    border-color: rgba(var(--color-base750), 1);
    color: rgba(var(--color-base750), 1);
}

.stepper__steps .step.has-error .step__status__dot {
    border-color: rgba(var(--color-error), 1);
    color: rgba(var(--color-error), 1);
    font-size: 24px;
    margin-top: -1px;
    margin-bottom: -1px;
}

 .stepper__steps .step.has-error .step__status__dot::before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    speak: none;
    text-decoration: none;
    text-transform: none;
    /* font-family: 'Poppins' !important;  */
    /* content: ""; */
}

.stepper__steps .step.is-finished .step__status__line {
    background-color: rgba(var(--color-success), 1);
}

 .stepper__steps .step__status:hover .step__status__line {
    background-color: rgba(var(--color-primary-dark), 1);
}

 .stepper__steps .step.is-finished .step__status:hover .step__status__line {
    background-color: rgba(var(--color-primary-dark), 1);
}

 .stepper__steps .step.is-finished .step__status:hover .step__status__line,
 .stepper__steps .step.has-error .step__status:hover .step__status__line {
    background-color: rgba(var(--color-primary-dark), 1);
}

.button {
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    /* font-family: 'Poppins',Arial,sans-serif;  */
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    margin: 8px 0px;
    padding: 8px 24px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    transition: background-color .2s ease-in-out, color .2s ease-in-out, border .2s ease-in-out !important;
}

.button.button--secondary {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid rgba(var(--color-primary), 1);
    color: rgba(var(--color-primary), 1);
    text-decoration: none;
}

 .button.button--secondary:hover {
    background-color: rgba(var(--color-primary-lightest), 1);
    text-decoration: none;
}

 .button.button--secondary:hover,
 .pager__page a.button--secondary:hover,
.forceDefaultElements button.button--secondary:hover,
.forceDefaultElements input.button--secondary[type="button"]:hover,
.forceDefaultElements input.button--secondary[type="reset"]:hover,
.forceDefaultElements input.button--secondary[type="submit"]:hover,
 a.button.button--secondary:hover {
    background-color: rgba(var(--color-primary-lightest), 1);
    text-decoration: none;
}

.button+.button {
    margin-left: 8px;
}

.stepper__steps .step__flyout__footer .footer__buttonRight {
    margin-left: auto;
}

.stepper__steps .step.is-disabled .step__status__line__wrapper .step__status__line {
    background-color: rgba(var(--color-base750), 1);
}

 .stepper__steps .step.is-disabled .step__status:hover .step__status__line {
    background-color: rgba(var(--color-base750), 1);
}

 .stepper__steps .step.is-disabled .step__status:hover .step__status__line,
 .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled .step.button .step__status:hover .step__status__line,
 .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps .step.button .step__status:hover .step__status__line,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled button.step .step__status:hover .step__status__line,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps button.step .step__status:hover .step__status__line,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="button"] .step__status:hover .step__status__line,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="button"] .step__status:hover .step__status__line,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="reset"] .step__status:hover .step__status__line,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="reset"] .step__status:hover .step__status__line,
.forceDefaultElements .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled input.step[type="submit"] .step__status:hover .step__status__line,
.forceDefaultElements .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps input.step[type="submit"] .step__status:hover .step__status__line,
 .stepper__steps .dropzone__container.dragAndDrop__area.is-disabled .pager__page a.step .step__status:hover .step__status__line,
 .dropzone__container.dragAndDrop__area.is-disabled .pager__page .stepper__steps a.step .step__status:hover .step__status__line,
 .stepper__steps .pager__page .dropzone__container.dragAndDrop__area.is-disabled a.step .step__status:hover .step__status__line,
 .pager__page .dropzone__container.dragAndDrop__area.is-disabled .stepper__steps a.step .step__status:hover .step__status__line,
 .stepper__steps .step.has-error.is-disabled .step__status:hover .step__status__line,
 .stepper__steps .step.is-finished.is-disabled .step__status:hover .step__status__line {
    background-color: rgba(var(--color-base750), 1);
}

.stepper__steps .step.has-error .step__status__line {
    background-color: rgba(var(--color-error), 1);
}

.fieldset {
    display: flow-root;
    border-radius: 3px;
    border: 1px solid rgba(var(--color-border), 1);
    font-size: 14px;
    min-width: 0;
    margin: 16px 0;
    padding: 13px 24px;
    overflow: auto;
}

 .stepper__steps .step__flyout__content> :first-child {
    margin-top: 0;
}

 .stepper__steps .step__flyout__content> :last-child {
    margin-bottom: 0;
}

.fieldset .legend {
    float: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.32em;
    margin-bottom: 14px;
    padding: 0;
}

.inputGroup {
    margin-bottom: 16px;
}

.fieldset .legend+* {
    clear: left;
}

.iconUxt {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    speak: none;
    text-decoration: none;
    text-transform: none;
    /* font-family: 'Poppins' !important; */
}

.button .iconUxt {
    display: inline-block;
    font-size: 24px;
    margin: 0 4px 0 -2px;
    vertical-align: bottom;
}

 .iconUxt.arrowSingleLeft::before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    speak: none;
    text-decoration: none;
    text-transform: none;
    /* font-family: 'Poppins' !important;  */
    /* content: ""; */
}

.button.button--primary {
    background-color: rgba(var(--color-primary), 1);
    border: 1px solid rgba(0, 0, 0, 0);
    color: rgba(var(--color-font-contrast), 1) !important;
    text-decoration: none;
}

 .button.button--primary:hover {
    background-color: rgba(var(--color-primary-light), 1);
    text-decoration: none;
}

 .button.button--primary:hover,
 .pager__page a.button--primary:hover,
.forceDefaultElements button.button--primary:hover,
.forceDefaultElements input.button--primary[type="button"]:hover,
.forceDefaultElements input.button--primary[type="reset"]:hover,
.forceDefaultElements input.button--primary[type="submit"]:hover,
 a.button.button--primary:hover {
    background-color: rgba(var(--color-primary-light), 1);
    text-decoration: none;
}

.inputGroup .radioButtonWrapper {
    margin: 0 0 8px 0;
    position: relative;
}

.inputGroup .inputGroup__label+.radioButtonWrapper {
    margin-top: 4px;
}

.inputGroup .radioButtonWrapper.radioButtonWrapper--alternative {
    background-color: rgba(var(--color-interface-highlight), 1);
    border-color: rgba(var(--color-border), 1);
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    display: flex;
    margin-bottom: 16px;
}

.inputGroup .radioButtonWrapper.radioButtonWrapper--alternative:last-child {
    margin-bottom: 0;
}

 .iconUxt.arrowSingleRight::before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    speak: none;
    text-decoration: none;
    text-transform: none;
    /* font-family: 'Poppins' !important;  */
    /* content: ""; */
}

.inputGroup__radioButton,
.inputGroup input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.inputGroup .radioButtonWrapper label {
    font-weight: normal;
}

.inputGroup__radioButton+label,
.inputGroup input[type="radio"]+label {
    font-size: 14px;
    color: rgba(var(--color-font), 1);
    display: inline-block;
    margin-left: 26px;
    text-indent: -26px;
}

.inputGroup .radioButtonWrapper.radioButtonWrapper--alternative .inputGroup__radioButton+label {
    align-items: center;
    background-color: rgba(var(--color-interface-secondary), 1);
    border-right: 1px solid rgba(var(--color-border), 1);
    display: flex;
    flex-basis: 64px;
    flex-shrink: 0;
    justify-content: center;
    margin: 0;
    transition: background-color .2s ease-in-out;
}

 .inputGroup__radioButton+label::before,
 .inputGroup input[type="radio"]+label::before {
    background: rgba(var(--color-interface), 1);
    border-radius: 100%;
    border: 1px solid rgba(var(--color-base600), 1);
    content: "";
    cursor: pointer;
    display: inline-block;
    height: 14px;
    margin-right: 10px;
    position: relative;
    text-align: center;
    transition: border 250ms ease-in-out, background-color 250ms ease-in-out;
    vertical-align: top;
    width: 14px;
}

 .inputGroup__radioButton:checked+label::before,
 .inputGroup input[type="radio"]:checked+label::before {
    background-color: rgba(var(--color-interface), 1);
    border: 1px solid rgba(var(--color-primary), 1);
    box-shadow: 0 0px 0px 3px rgba(var(--color-primary), 1) inset;
}

 .inputGroup__radioButton+label:empty::before,
 .inputGroup input[type="radio"]+label:empty::before {
    margin-right: 0;
}

 .inputGroup .checkboxWrapper.checkboxWrapper--alternative .inputGroup__radioButton+label::before,
 .inputGroup .checkboxWrapper.checkboxWrapper--alternative .inputGroup__checkbox+label::before,
 .inputGroup .radioButtonWrapper.radioButtonWrapper--alternative .inputGroup__radioButton+label::before,
 .inputGroup .radioButtonWrapper.radioButtonWrapper--alternative .inputGroup__checkbox+label::before {
    left: 0 !important;
}

.inputGroup .radioButtonWrapper.radioButtonWrapper--alternative .radioButtonWrapper--alternative__content {
    margin: 16px;
    font-size: clamp(.73rem, .79rem - .05vw, .78rem);
    line-height: 1.32em;
}

div:not(.rendered-snippet)>p {
    line-height: 21px;
}

.inputGroup .radioButtonWrapper.radioButtonWrapper--alternative .radioButtonWrapper--alternative__header+p {
    margin-top: 0;
}

.inputGroup .radioButtonWrapper.radioButtonWrapper--alternative .radioButtonWrapper--alternative__content p:last-child {
    margin-bottom: 0;
}


.k-switch {
    border-color: rgba(var(--color-primary), 1) !important;
}

.k-switch-on .k-switch-track {
    border-color: rgba(var(--color-primary), 1) !important;
    color: white;
    background-color: rgba(var(--color-primary), 1) !important;
}



.k-radio:checked,
.k-radio.k-checked,.spec-container .k-checkbox:checked {
    color: white;
    background-color: rgba(var(--color-primary), 1) !important;
}

.spec-container .k-checkbox,.spec-container .k-radio {
    outline: 1px solid rgba(var(--color-primary));
    border-color: transparent !important;
}

.spec-container .k-checkbox:focus,.k-radio:focus{
    box-shadow: 0 0 0 2px rgba(var(--color-primary), 0.3) !important;
}


.spec-container .k-checkbox[data-edit="AdFeatures"]{
    outline: 1px solid rgb(0, 230, 69);
}
.spec-container .k-checkbox[data-edit="AdFeatures"]:focus{
      box-shadow: 0 0 0 2px rgba(0, 230, 69, 0.3) !important;
}
.spec-container .k-checkbox[data-edit="AdFeatures"]:checked{
    background-color: rgb(0, 230, 69) !important;
}
div.group[data-edit="AdFeatures"]>div.title{
background: rgba(0,230,69, 25%);
}
div.prop-container{
    opacity:1;
    transition: opacity 300ms;
}

div.prop-container:has(.k-checkbox:not(:checked)) {
    opacity: 0.6;
}

