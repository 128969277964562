:root {
  --pink: #e11d4b;
  --dark: #171717;
  --black: #020202;
  --light: #FFFFFF;
  --forest: #46c697;
  --gray: #A5A5A4;
  --platinum: #6a8b84;

  --platinum: #758f8b;
  --titanium: #7a7a73;
  --gold: #D4AF37;
  --silver: #C0C0C0;
  --bronze: #CD7F32;
}


.pink {
  color: var(--pink);
}

.dark {
  color: var(--dark);
}

.light {
  color: var(--light);
}

.green {
  color: var(--forest);
}

.gray {
  color: var(--gray);
}

.Platinum {
  color: var(--platinum);
}

.Titanium {
  color: var(--titanium);
}

.Gold {
  color: var(--gold);
}

.Silver {
  color: var(--silver);
}

.Bronze {
  color: var(--bronze);
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: 'Oswald', sans-serif; */
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root,
#__next {
  isolation: isolate;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none !important;
}

/* Root Container Styling */
#root {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

main {
  width: 100%;
}

nav {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.150);
}

.nav-toggle {
  display: none;
  /* margin: 15px auto; */
  display: flex;
  justify-content: flex-end;
  width: 100%;
  /* visibility: hidden; */
}

.toggle-button {
  display: none;
  border: 2px solid #dee2e6;
  border-radius: 10px;
  padding: 15px;
}

.toggle-brand {
  display: none;
  border-radius: 10px;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: .15rem;
  font-family: 'Poppins', sans-serif;
}

.toggle-brand a {
  text-decoration: none;
  color: var(--dark);
}

.show {
  display: flex !important;
  ;
}

.brand-show {
  visibility: hidden;
}

.car-navbar {
  max-width: 1200px;
  width: 100%;
  /* font-family: 'Poppins', sans-serif; */
  font-size: 1.1rem;
  display: flex;
  justify-content: space-between;
  align-self: center;
  gap: 30px;
  padding: 10px 0;
  color: var(--dark);
  text-transform: uppercase;
  padding: 15px 0;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
}

.nav-icon {
  font-weight: 500;
  width: fit-content;
  font-size: 1.3rem;
}

.nav-icon .car-highlight {
  font-weight: 300 !important;
}

.nav-icon,
.nav-item,
.contact-button {
  display: flex;
  align-items: center;
}

.nav-item a {
  cursor: pointer;
  font-weight: 300 !important;
}

.car-navbar .custom-button {
  margin-bottom: 0px;
}

.car-navbar .nav-item a,
.car-navbar .nav-icon a {
  color: var(--dark);
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  display: inline-block;
  padding: 15px 0;
  position: relative;
}

.car-navbar a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  background: none repeat scroll 0 0 transparent;
  height: 1.75px;
  width: 0;
  background: var(--pink);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
}

.car-navbar a:hover::after {
  width: 100%;
  left: 0;
}

footer {
  max-width: 1200px;
  width: 100%;
  /* font-family: 'Poppins', sans-serif; */
}

footer ul {
  padding: 0;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.footer a {
  font-weight: 400;
  color: var(--dark) !important;
}

.page-content {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.padding-fix {
  padding: 100px 0;
}

/*! SECTION 1 STYLING */
.car-find {
  padding: 50px 0;

  max-width: 1200px;
  width: 100%;
  background-color: var(--light);
  ;
  display: flex;
  justify-content: space-between;
  background-position: 390px 50px;
  background-repeat: no-repeat;
  background-image: url('https://www.metrolexus.com/static/brand-lexus/vehicle/2022/NX/LEX-NXG-MY22-0071.01_E01-Base250FWD-Ext1-AtomicSilver-W18inStdG4AD.png');
}

.car-find-description {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.car-sm-description {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  font-family: 'Oswald', sans-serif;
}

.car-md-description {
  text-transform: uppercase;
  font-size: 2.7rem;
  font-weight: 600;
  line-height: 55px;
  font-family: 'Oswald', sans-serif;
}

.car-lg-description {
  text-transform: uppercase;
  font-size: 4rem;
  font-weight: 600;
  line-height: 55px;
  width: 370px;
  font-family: 'Oswald', sans-serif;
}

.car-paragraph {
  font-size: 1rem;
  font-weight: 300;
  width: 400px;
  /* font-family: 'Poppins', sans-serif; */
}

/*! CAR CATEGORY STYLING */
.car-search-container {
  width: 100%;
  max-width: 1200px;
  margin: 5px auto;
  /* font-family: 'Poppins', sans-serif; */

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.car-search-container .form-floating {
  width: 190px;
}

.inventory-container .car-search-container {
  margin: 30px auto;
}

.car-search-container .custom-button,
.car-search-container .custom-reset-button {
  margin: 0;
  height: 60px;
  border-radius: 5px !important;
  font-weight: 400;
  text-transform: none;
}

.car-category-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px 0;
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  gap: 10px;
  overflow-y: auto;
}

.car-category {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  width: 120px;
  height: 120px;
  cursor: pointer;
  padding: 10px;
  flex: 0 0 auto;
  transition: 50ms ease-in-out;
}

.car-category img {
  width: 70%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
  /* flex: 0 0 auto;
  display: flex;
  flex-direction: column; */
}

.car-category-type {
  /* font-family: 'Poppins', sans-serif; */
  font-weight: 500;
  font-size: .8rem;
  display: flex;
  justify-content: center;
  color: var(--dark);
}

.car-category:hover {
  background-color: #e9ecef;
}

.category-selected {
  background-color: #e9ecef;
}


/* 
.car-catergory img {
  filter: brightness(.9);
  transition: 100ms ease-in-out;
}

.car-catergory img:hover {
  filter: brightness(1);
} */

/*! TO RESTORE, REMOVE COMMENT */
/* .car-find-input-container {
  display: flex;
  width: 550px;
  padding: 10px 15px;
  margin: 50px 0 0 0;
  border: 1px solid rgba(0, 0, 0, 0.150);
  justify-content: space-between;
  align-items: flex-end;
  border-radius:5px;
  background-color: white;
} */

/* .custom-dropdown {
  width: fit-content;
  text-align: left;
  display: flex;
  flex-direction: column;
  background-color: var(--light) !important;
  border: none !important;
  border-radius: 0px !important;
  padding: 5px 0;
} */

.custom-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  text-transform: uppercase !important;
  background-color: var(--pink) !important;
  border: none !important;
  border-radius: 0px !important;
  width: fit-content;
  height: 50px;
  /* margin-bottom: 7px; */
  padding: 0 !important;
}

.custom-reset-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-transform: uppercase !important;
  background-color: var(--dark) !important;
  border: none !important;
  border-radius: 0px !important;
  width: fit-content;
  height: 50px;
  padding: 0 !important;
}

.custom-outline-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-transform: uppercase !important;
  background-color: transparent !important;
  border: 1px solid var(--pink) !important;
  color: var(--pink) !important;
  border-radius: 0px !important;
  width: fit-content;
  height: 50px;
  /* margin-bottom: 7px; */
  padding: 0 !important;

  width: 100%;
}

.custom-next-button {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  text-transform: uppercase !important;
  background-color: var(--dark) !important;
  border: none !important;
  border-radius: 0px !important;
  width: fit-content;
  height: 50px;
  padding: 0 !important;
  width: 220px !important;
}

/* .custom-carFax-button {
  text-transform: uppercase !important;
  background-color: transparent !important;
  border: none !important;
  color: var(--pink) !important;
  border-radius: 0px !important;
  width: 100%;
  padding: 0 !important;
}

.custom-carFax-button a {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
}

.custom-carFax-button a img {
  width: fit-content;
  height: 50px;
} */


.custom-button a,
.custom-reset-button a,
.custom-outline-button a,
.custom-carFax-button a {
  color: var(--white);
  text-decoration: none;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0 15px; */
}

.show-result-fix {
  padding: 0 15px !important;
}

/*! SECTION 2 STYLING */
.car-help {
  width: 100%;
  background-color: var(--black);
  ;
  /* padding: 80px 0;  */
  /* margin: 80px 0; */
}

.car-help-container {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.car-help-description {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.car-help-description .car-md-description {
  width: fit-content;
}

.car-help-description .car-paragraph {
  width: 50%;
  text-align: center;
}

.car-help-steps {
  max-width: 1200px;
  width: 100%;
  margin: 25px auto;
  display: flex;
  justify-content: space-between;
}

.car-step {
  width: 29.5%;
  /* height: 350px; */
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 25px;
  /* border: 3px solid #40404089; */
  background-color: #e9ecef;
  /* font-family: 'Oswald', sans-serif; */
}

.car-step-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.car-step-logo {
  width: 60px;
  height: 60px;
  border: 3px solid var(--pink);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.car-step-number {
  color: var(--pink);
  font-weight: 500;
  font-size: 2rem;
}

.help-desc {
  color: var(--dark);
  font-weight: 500;
  font-size: 1.4em;
}

.help-para {
  color: var(--dark);
  font-weight: 300;
  font-size: 1rem;
  word-spacing: 2px;
}

/*! SECTION 3 STYLING */
.car-featured-container {
  width: 100%;
  max-width: 1200px;
  background-color: var(--light);
  ;
}

.car-featured-title-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 auto;
}

.car-highlight {
  font-weight: 300;
}

.car-featured-title {
  display: flex;
  flex-direction: column;
}

/*! SECTION 4 STYLING */
.car-brands-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.car-brand {
  width: 16%;
  height: 100px;
  background-color: var(--light);
  ;
  display: flex;
  justify-content: center;
  align-items: center;
}


/*! SECTION 5 STYLING */
.car-contact {
  width: 100%;
}

.car-contact-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  /* margin: 50px auto; */
  margin: 30px auto;

}

.car-contact-information {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* font-family: 'Poppins', sans-serif; */
}

.car-dealership {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.car-contact-title {
  width: 100%;
  font-weight: 500;
  font-size: 2rem;
}

.contact-description {
  width: 100%;
  font-weight: 300;
  font-size: 1rem;
  /* font-family: 'Poppins', sans-serif; */
}

form {
  width: 100%;
  /* font-family: 'Poppins', sans-serif; */
  font-size: .9rem;
}

.car-contact-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--light);
  ;
  width: 45%;
  gap: 15px;
}

.form-group {
  display: flex;
  justify-content: space-between;
}

.product-financing-calculator .form-child {
  width: 32.5%;
}

.form-child {
  width: 50%;
}

.car-dealership-title {
  font-size: 2rem;
}

.car-dealership-info {
  font-size: 1.2rem;
  font-weight: 300;
}

/*! STYLING FOR THE CAR PREVIEW */
.preview-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  margin: 15px auto;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: none !important;
  /* font-family: 'Poppins', sans-serif; */
}

/* .car-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.car-listing-column {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 0;
  border: 1px solid rgba(0,0,0, .125);
}

.car-listing-column .product-img {
  width: 100%;
}

.car-listing-column .product-details {
  width: 100%;
  padding: 0 20px;
}

.car-listing-column .product-report {
  width: 100%;
} */

/* .car-listing {
  width: 31.5%;
  height: 570px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.15);
  gap: 20px;
  box-shadow: 3px 10px 5px 1px rgba(0, 0, 0, 0.100);
  background-color: whitesmoke;
}

.car-listing .custom-button {
  width: 100%;
}

.car-image-container {
  width: 100%;
  height: 250px;
  position: relative;
}

.car-listing img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(.9);
  transition: 150ms ease-in-out;
}

.car-listing img:hover {
  filter: brightness(1);
}

.car-listing .custom-button {
  background-color: var(--dark) !important;
  width: 100%;
  height: 50px;
  margin: 0px;
}

.car-title {
  width: 80%;
  font-weight: 400;
  font-size: 1.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.price {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 30px;
  width: 80%;  
}

.price-container {
  display: flex;
  flex-direction: column;
}

.new-price-title {
  color: var(--pink);
  font-weight: 400;
  font-size: 1.2rem;
}

.new-price {
  font-weight: 500;
  font-size: 1.2rem;
}

.old-price-title {
  color: var(--gray);
  font-weight: 300;
}

.old-price {
  font-size: 1rem;
  font-weight: 300;
}

.car-description {
  width: 80%;
  font-weight: 300;
}

.car-links {
  display: flex;
  justify-content: center;
  width: 80%;
  gap: 10px;
}

.car-link {
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgba(39, 93, 209, 0.997);
  font-weight: 400;
  cursor: pointer;
}

.car-divider {
  color: var(--gray);
} */

/*! RESPONSIVE STYLING */
@media screen and (max-width: 1280px) {

  /*! CAR SEARCH STYLING*/
  .car-search-container .form-floating:nth-child(5) {
    width: 150px;
  }

  /*! NAVBAR STYLING */
  .car-navbar {
    width: 90%;
  }

  /*! SECTION 1 STYLING */
  .car-find {
    width: 90%;
  }

  /*! SECTION 2 STYLING */
  .car-help-container {
    width: 90%;
  }

  .car-help-description .car-paragraph {
    width: 60%;
  }

  .car-help-steps {
    width: 90%;
  }

  /*! SECTION 3 STYLING */
  .car-featured-title-container {
    width: 90%;
  }

  .preview-container {
    width: 90%;
  }

  /*! SECTION 5 STYLING */
  .car-contact-container {
    width: 90%;
  }

  /*! INVENTORY STYLING */
  .inventory-container {
    width: 90% !important;
  }

  /*! CAR DETAIL STYLING*/
  .car-detail-container {
    width: 90% !important;
  }

  /*! BOOK APPOITMENT STYLING */
  .book-appointment-container {
    width: 90% !important;
  }

  .car-listing-grid-view {
    max-width: 50% !important;
  }
}


@media screen and (max-width: 1260px) {

  /*! CAR SEARCH STYLING*/
  .car-search-container {
    gap: 15px;
  }

  .car-search-container .form-floating {
    width: 32% !important;
  }

  .car-search-container .form-floating:nth-child(4) select {
    width: 100%;
  }

  .car-search-container .btn {
    width: 32%;
  }

  /*! SECTION 2 STYLING */
  .car-step {
    width: 31.5%;
  }

  .car-step-number {
    font-size: 1.75rem;
  }

  .help-desc {
    font-size: 1.50rem;
  }

  .help-para {
    font-size: 1.20rem;
  }

  /*! BOOK APPOINTMENT STYLING */
  .booking-left {
    width: 67% !important;
  }

  .booking-right {
    width: fit-content !important;
  }

  .calander-container-child {
    flex-direction: column !important;
    gap: 10px;
  }

  .time-slots-container {
    flex-direction: row !important;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
    width: 360.17px !important;
  }

  .time-slot {
    width: 48.5%;
  }
}

@media screen and (max-width: 1220px) {

  /*! NAVBAR STYLING */
  .car-navbar {
    font-size: 1rem;
  }

  .nav-icon {
    font-size: 1.3rem;
  }

  /*! SECTION 2 STYLING */
  .inventory-container .car-help-description .car-paragraph {
    width: 100%;
  }

  .car-help-description .car-paragraph {
    width: 80%;
  }

  .inventory-container .car-help-steps {
    width: 100%;
  }

  .car-help-steps {
    width: 90%;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .car-step {
    margin-bottom: 25px;
  }

  .car-step:first-child {
    width: 48.5%;
    min-height: 300px;
  }

  .car-step:nth-child(2) {
    width: 48.5%;
    min-height: 300px;
  }

  .car-step:nth-child(3) {
    width: 100%;
    height: 100%;
  }

  .car-step-number {
    font-size: 2rem;
  }

  .help-desc {
    font-size: 1.75rem;
  }

  .help-para {
    font-size: 1.4rem;
  }

  /*! SECTION 3 STYLING */
  .car-image-container {
    height: 220px;
  }

  .car-title {
    font-size: 1.25rem;
  }

  .price {
    justify-content: space-between;
    gap: 0px;
  }

  .new-price-title {
    font-size: 1rem;
  }

  .old-price-title {
    font-size: .9rem;
  }

  /*! CAR LISTING WIDE STYLING */
  .car-listing-wide {
    flex-wrap: wrap;
  }

  .product-img {
    width: 50% !important;
    padding: 10px 20px !important;
  }

  .product-details {
    width: 50% !important;
    padding: 10px 20px 10px 0 !important;
  }

  .product-report {
    padding: 0 20px !important;
    width: 100% !important;
    flex-direction: row !important;
  }

  .product-report-buttons:nth-child(1) {
    width: 100%;
    flex-direction: row !important;
  }

  /* .product-report-buttons:nth-child(2) {
    width: 40%;
    flex-direction: row !important;
  } */

  .hide-product-detail {
    display: flex !important;
  }

  .product-report .product-tier-container {
    display: none;
  }

  .product-tier-container {
    width: 400px;
  }

  /* .product-tier-container div{
    width: 130px !important;
  } */

  .car-listing-grid-view .product-img {
    width: 100% !important;
    height: 30% !important;
  }

  .car-listing-grid-view .product-details {
    width: 100% !important;
    padding: 20px !important;
  }

  .car-listing-grid-view .product-report {
    width: 100% !important;
  }


  /*! CAR DETAIL STYLING*/
  .product-detail {
    flex-direction: column;
    height: 100% !important;
  }

  .detail-gallery {
    height: 800px !important;
  }

  .tab-highlighted {
    height: 82% !important;
  }

  .tab-navigation {
    height: 18% !important;
  }

  .product-detail-buttons {
    flex-direction: row !important;
  }

  .product-detail .detail-gallery {
    width: 100% !important;
    margin-bottom: 0 !important;
  }

  .hideAfterResize {
    display: none !important;
  }

  .product-detail .detail-specifications {
    width: 100% !important;
    padding: 15px 0;
  }

  .product-detail .product-details {
    width: 100% !important;
  }

  .product-financing-calculator {
    width: 100% !important;
  }

  .tab-navigation .gallery-tab {
    width: 20% !important;
  }

  /* .product-detail .product-tier-container {
    width: 100% !important;
  } */

  /*! INVENTORY GRID-VIEW STYLING */
  /* .car-listing-grid-view {
    height: 800px !important;
  } */

  .car-listing-grid-view .product-img {
    height: 330px !important;
  }

  .car-listing-grid-view .product-details {
    padding: 5px 20px !important;
  }

  .car-listing-grid-view .product-report-buttons {
    padding: 15px 0 !important;
  }
}

@media screen and (max-width: 1075px) {

  /*! NAVBAR STYLING */
  .car-navbar {
    font-size: .9rem;
  }

  .nav-icon {
    font-size: 1.1rem;
  }

  .car-navbar a {
    padding: 15px 0px;
  }

  /*! SECTION 5 STYLING */
  .car-contact-container {
    flex-direction: column-reverse;
  }

  .car-contact-form {
    width: 100%;
    margin-bottom: 20px;
  }

  .car-contact-information {
    width: 100%;
    flex-direction: column-reverse;
  }

  .warranty-progress-tracker div {
    font-size: 1.4rem !important;
  }
}

@media screen and (max-width: 980px) {

  /*! NAVBAR STYLING */
  .car-navbar {
    font-size: .8rem;
  }

  .nav-icon {
    font-size: 1rem;
    width: fit-content;
  }

  .car-navbar a {
    padding: 10px 0px;
  }

  /*! SECTION 3 STYLING */
  .price {
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 80%;
    gap: 15px;
  }

  .new-price-title {
    font-size: 1.2rem;
  }

  .old-price-title {
    font-size: 1.1rem;
  }

  .price-container:nth-child(1) {
    width: fit-content
  }

  .price-container:nth-child(2) {
    width: fit-content
  }

  .price-container:nth-child(3) {
    width: 100%;
  }

  /*! SECTION 4 STYLING */
  .car-brand {
    width: 22%;
  }

  .car-brand:nth-child(5) {
    display: none;
  }

  /*! CAR LISTING WIDE STYLING */
  .car-listing-wide {
    flex-wrap: wrap;
  }

  .product-img {
    width: 40% !important;
  }

  .product-details {
    width: 60% !important;
  }

  .warranty-progress-tracker div {
    font-size: 1.2rem !important;
  }

  /* .car-listing-grid-view  {
    max-width: 100% !important;
  } */
}

@media screen and (max-width: 910px) {
  .warranty-progress-tracker div {
    font-size: 1.1rem !important;
  }

  /*! CAR SEARCH STYLING*/
  .car-search-container .form-floating {
    width: 48.5% !important;
  }

  .car-search-container .btn {
    width: 48.5%;
  }

  /*! NAVBAR STYLING */
  .nav-toggle {
    display: flex;
  }

  .car-navbar {
    display: none;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    padding-bottom: 30px;
  }

  .nav-toggle {
    /* position: absolute; */
    right: 5%;
    top: 0;
    display: flex;
    margin: 15px auto;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1200px;
  }

  .toggle-button {
    display: flex;
  }

  .toggle-brand {
    display: flex;
  }

  .nav-icon {
    font-size: 1.1rem;
  }

  .car-navbar a {
    padding: 10px 0px;
  }

  /*! SECTION 1 STYLING */
  .car-find {
    padding-top: 70px;
  }

  .car-category-container {
    justify-content: flex-start;
  }

  /*! SECTION 2 STYLING */
  .car-help-steps {
    flex-direction: column;
    gap: 5px
  }

  .car-step {
    margin-bottom: none;
    width: 100% !important;
    height: fit-content;
  }

  .about-us-child {
    width: 100% !important;
  }

  /*! SECTION 3 STYLING */
  .car-list-container {
    flex-wrap: wrap;
  }

  .car-listing {
    margin-bottom: 30px;
  }

  .car-listing:nth-child(1) {
    width: 48%;
    height: 600px !important;
  }

  .car-listing:nth-child(2) {
    width: 48%;
    height: 600px !important;
  }

  .car-listing:nth-child(3) {
    width: 48%;
    height: 600px !important;
  }

  /*! CAR LISTING WIDE STYLING */
  .car-listing-wide .product-details {
    padding: 0 20px !important;
  }

  .car-listing-wide {
    flex-wrap: wrap;
  }

  /* .product-img {
    margin: 0 auto;
    width: 500px !important;
  } */
  .product-img {
    width: 100% !important;
    padding: 10px 20px !important;
  }

  .product-details {
    width: 100% !important;
    gap: 7px !important;
  }

  .product-report {
    padding: 7px 20px !important;
    width: 100% !important;
  }

  /* 
  .product-report-buttons {
    flex-direction: column !important;
  }
   */

  /* .product-details {
    width: 50% !important;
    padding: 10px 20px 10px 0 !important;
  } */

  /*! CAR DETAIL STYLING*/
  /* .car-detail-container {
    margin: 40px auto !important;
  } */

  .detail-gallery {
    height: 650px !important;
  }

  .tab-highlighted {
    height: 77% !important;
  }

  .tab-navigation {
    height: 23% !important;
  }

  .car-detail-header {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 15px;
    /* margin-top: 25px; */
  }

  .tab-highlighted .gallery-tab img {
    height: 100% !important;
    /* object-fit: fill !important; */
  }

  .tab-navigation .gallery-tab {
    width: 25% !important;
  }

  .car-listing-grid-view {
    max-width: 100% !important;
    padding: 15px 0;
  }


  .car-listing-grid-view .product-report-buttons {
    padding: 0 0 !important;
  }

  .car-listing-grid-view .product-img {
    height: 100% !important;
  }

  .grid-view-toggle {
    display: none !important;
  }
}


@media screen and (max-width: 750px) {
  .warranty-progress-tracker div {
    font-size: 1rem !important;
  }

  /*! BOOK APPOINTMENT STYLING*/
  .book-appointment-form form {
    flex-direction: column-reverse !important;
    gap: 50px;
    margin-bottom: 100px;
  }

  .calander-container-child {
    flex-direction: row !important;
  }

  .calander-container {
    width: 100% !important;
  }

  .time-slots-container {
    width: 45% !important;
    height: 100% !important;
  }

  .book-appointment-container {
    height: 100% !important;
  }

  .booking-right {
    justify-content: flex-start !important;
    width: 100% !important;
  }

  .booking-left {
    width: 100% !important;
  }

  /* .product-report {
    flex-wrap: wrap !important;
  } */

  .product-report-buttons {
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    gap: 7px 0 !important;
  }

  .product-report-buttons button:nth-child(1) {
    width: 49.35%;
  }

  .product-report-buttons button:nth-child(2) {
    width: 49.35%;
  }

  /* 
  .product-report-buttons button:nth-child(3) {
    width: 100%;
  } */
}

@media screen and (max-width: 700px) {

  /*! BOOK APPOINTMENT STYLING*/
  .calander-container-child {
    flex-direction: column !important;
  }

  .time-slots-container {
    width: 100% !important;
  }

  .car-contact-title {
    font-size: 1.5rem;
  }

  /*! WARRANTY STYLING*/
  .warranty-progress-tracker {
    justify-content: flex-start !important;
  }

  .warranty-progress-tracker div {
    font-size: 1.5rem !important;
    display: none;
  }

  .warranty-progress-selected {
    display: flex !important;
  }
}

@media screen and (max-width: 640px) {

  /*! CAR SEARCH STYLING*/
  .car-search-container .form-floating {
    width: 100% !important;
  }

  .car-search-container .btn {
    width: 100%;
  }

  d

  /*! SECTION 3 STYLING */
  .car-md-description {
    width: fit-content;
  }

  .car-listing {
    width: 100% !important;
    margin: 20px auto;
  }

  /*! SECTION 4 STYLING */
  .car-brand {
    width: 30%;
  }

  .car-brand:nth-child(4) {
    display: none;
  }

  /*! CAR LISTING WIDE STYLING */
  .car-listing-wide {
    flex-wrap: wrap;
  }

  .product-img {
    margin: 0 auto;
    width: 100% !important;
  }

  .product-details {
    width: 100% !important;
  }

  .product-pagination {
    display: none !important;
  }

  /*! CAR DETAIL STYLING*/
  .detail-gallery {
    height: 550px !important;
  }

  .detail-title-milage {
    display: none;
  }

  .detail-title {
    font-size: 1.7rem !important;
  }

  .detail-specs {
    font-size: 1rem !important;
  }

  .tab-navigation .gallery-tab {
    width: 33% !important;
  }

  .product-detail-buttons {
    flex-direction: column !important;
  }

  /*! FINANCE CALCULATOR STYLING */
  .product-financing-calculator .form-group {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }

  .product-financing-calculator .form-child {
    width: 48.5%;
  }

  .product-financing-calculator .form-floating:nth-child(1) {
    width: 100%;
  }

  .product-financing-calculator .form-floating:nth-child(2) {
    width: 48.5%;
  }

  /* .product-financing-calculator .form-floating:nth-child(3) {
    width:  48.5%;
  } */

  .warranty-form .form-group {
    flex-direction: column;
  }

  .warranty-form .form-group .form-child {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {

  /*!NAVBAR STYLING */
  .nav-toggle {
    right: 7.5%;
    width: 85%;
  }

  /*! FOOTER STYLING */
  footer {
    width: 85% !important;
  }

  /*! INVENTORY STYLING */
  .inventory-container {
    width: 85% !important;
  }

  /*! SECTION 1 STYLING */
  .car-find {
    width: 85% !important;
    background-image: none;
  }

  .car-lg-description {
    width: 100%;
  }

  .car-paragraph {
    width: 100%;
  }

  /*! SECTION 2 STYLING */
  .car-help-steps {
    width: 85% !important;
  }

  .car-step-number {
    font-size: 1.5rem;
  }

  .help-desc {
    font-size: 1.3rem;
  }

  .help-para {
    font-size: 1.1rem;
  }

  /*! SECTION 3 STYLING */
  .car-featured-title-container {
    width: 85% !important;
    flex-direction: column;
    align-items: flex-start !important;
    gap: 5px;
  }

  .car-featured-title-container button {
    width: 100% !important;
  }

  .preview-container {
    width: 85% !important;
  }

  .car-listing {
    width: 100% !important;
  }

  .car-md-description {
    font-size: 2.2rem;
  }

  /*! SECTION 4 STYLING */
  .car-brands-container {
    margin: 15px 0;
  }

  .car-brand {
    width: 45%;
  }

  .car-brand:nth-child(3) {
    display: none;
  }

  .car-paragraph {
    width: 100% !important;
  }

  /*! SECTION 5 STYLING */
  .car-help-container {
    width: 85% !important;
  }

  .car-contact-container {
    width: 85% !important;
  }

  /*! CAR WIDE LISTING STYLING */
  .product-tier-container {
    width: 100%;
    overflow-y: auto;
  }

  .product-tier-container div {
    width: 120px !important;
    display: flex;
    flex: 0 0 auto;
    padding-bottom: 7px;
  }

  .car-listing-wide .product-tier-container {
    width: 100%;
    overflow-y: auto;
  }

  .car-listing-wide .product-tier-container div {
    width: 100px !important;
    display: flex;
    flex: 0 0 auto;
    padding-bottom: 7px;
  }

  .car-listing-wide .product-specs-lists,
  .car-listing-grid-view .product-specs-lists {
    width: 100% !important;
    overflow-y: auto;
    flex-wrap: nowrap;
    padding-bottom: 7px;
  }

  .car-listing-wide .product-spec,
  .car-listing-grid-view .product-spec {
    display: flex;
    flex: 0 0 auto;
  }

  /* .hide-product-detail .product-carfax {
    display: none;
  } */

  /*! CAR DETAIL STYLING*/
  .car-detail-container {
    width: 85% !important;
  }

  .tab-navigation .gallery-tab {
    /* padding: 0 5px !important; */
    padding-right: 5px !important;
    /* margin-right: 5px; */
  }

  .detail-gallery {
    height: 100% !important;
  }

  .detail-gallery .tab-navigation .gallery-tab {
    width: 33.3% !important;
  }

  .tab-navigation {
    height: 100px !important;
  }

  /*! CAR ERROR PAGE */
  .error-container {
    width: 85% !important;
    margin: 120px auto !important;
  }

  .error-code {
    font-size: 8rem !important;
  }

  .error-message {
    font-size: 1.5rem !important;
  }

  .error-description {
    font-size: 1rem !important;
    text-align: center;
  }

  /*! FINANCE CALCULATOR STYLING */
  .product-financing-calculator .form-child {
    width: 100% !important;
  }


  /*! CARFAX VIEW BUTTON */
  .custom-carFax-button a {
    flex-direction: column !important;
    gap: 5px;
  }

  .custom-carFax-button img {
    display: none;
  }

  /*! CAR LISTING WIDE STYLING */
  .product-report-buttons {
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    gap: 7px 0 !important;
  }

  .product-report-buttons button:nth-child(1) {
    width: 100%;
  }

  .product-report-buttons button:nth-child(2) {
    width: 100%;
  }

  .product-report-buttons button:nth-child(3) {
    width: 100%;
  }

  /*! BOOK APPOINTMENT STYLING*/
  .time-slots-container {
    width: 100% !important;
  }

  .calander-container {
    width: 100% !important;
  }

  /*! BOOK APPOINTMENT STYLING*/
  .book-appointment-container {
    width: 85% !important;
  }

  .k-calendar-infinite {
    width: 100% !important;
  }

  /* .result-header {
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: center !important;
    gap: 10px;
  } */

  .result-header {
    flex-direction: column;
    gap: 10px;
  }

  .car-contact-title {
    font-size: 1.15rem;
  }

  /*! CAR INVENTORY SORT BY CONTAINER*/
  .grid-and-sort-container {
    text-align: center;
    width: 100% !important;
  }

  .results-sort {
    width: 100% !important;
  }

  .results-sort select {
    text-align: center;
  }
}


@media screen and (max-width: 400px) {

  /*! CAR DETAILS PAGE STYLING */
  .vehicle-overview-specs {
    flex-direction: column;
  }

  .vehicle-overview-spec {
    width: 100% !important;
  }

  .vehicle-specs {
    flex-direction: column;
  }

  .vehicle-spec {
    width: 100% !important;
  }

  .vehicle-spec div {
    width: 100%;
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    font-size: 0.95rem;
  }

  .vehicle-spec div .vehicle-spec-detail {
    display: flex;
    justify-content: flex-end;
  }

  .k-calendar-navigation {
    display: none !important;
  }
}

@media screen and (max-width: 350px) {
  /*! CAR WIDE LISTING STYLING */
  /* 
  .car-listing-wide .product-tier-container {
    display: none !important;
  } */

  /*! CAR DETAILS PAGE STYLING */
  .product-detail-price {
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 0px !important;
  }

  .detail-gallery .tab-navigation .gallery-tab {
    width: 50% !important;
  }

  .time-slot {
    width: 100%;
  }
}

/*?INVENTORY STYLING */
.inventory-container {
  max-width: 1200px;
  width: 100%;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  /* font-family: 'Poppins', sans-serif; */
}

.inventory-title {
  width: 100%;
  margin: 0 auto;

  font-weight: 500;
  font-size: 2rem;
  text-transform: uppercase;
}

.inventory-results {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.result-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
  padding: 10px;

  font-weight: 300;
  font-size: 1.1rem;
}

.result-num-bold {
  font-weight: 500;
}

.grid-and-sort-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.inventory-cars {
  border-left: 1px solid rgba(0, 0, 0, .125);
  border-right: 1px solid rgba(0, 0, 0, .125);
  /* border-bottom: 1px solid rgba(0,0,0,.125); */
}

.car-listing-wide {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.car-listing-wide .product-details {
  padding: 20px 0;
}

.product-img {
  width: 30%;
  padding: 20px;
}

.product-img img {
  /* border: 1px solid rgba(0,0,0, .125); */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-details {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.product-title {
  font-weight: 600;
  font-size: 1.2rem;
  cursor: pointer;
}

.product-title a {
  color: var(--dark);
}

.product-specs {
  font-weight: 300;
  font-size: 1rem;
}

.product-price {
  font-weight: 300;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 5px;
}

.product-taxes-and-licensing {
  font-weight: 400;
  font-size: .8rem;
}

.price-listing {
  font-size: 1.35rem;
  color: var(--pink);
  font-weight: 600;
}

.product-financing-prg {
  font-weight: 400;
  font-size: 1rem;
}

.financing-price {
  color: var(--pink);
  font-weight: 600;
}

.financing-taxes {
  font-weight: 300;
  font-size: 1.35rem;
}

.detail-title-milage {
  font-weight: 300;
  font-size: 1.35rem;
}

.taxes-and-licensing-fees {
  font-weight: 300;
  font-size: .8rem;
}

.product-milage {
  font-weight: 400;
  font-size: 1rem;
}

.product-milage-bold {
  font-weight: 600;
}

.product-specs-lists {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.product-spec {
  width: fit-content;
  padding: 7px 10px;
  font-weight: 400;
  font-size: .8rem;
  color: var(--light);
  background-color: var(--dark);
}

.product-report {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 7px;
  padding: 20px;
}

.product-report-buttons {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.product-report-buttons button a {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product-report .custom-button,
.product-report .custom-reset-button {
  width: 100%;
  margin: 0;
}

.product-tier-container {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: flex-start;
  gap: 0 5px;
}

.product-tier-container img {
  width: 100%;
  height: 100%;
}

.hide-product-detail {
  display: none;
}

.product-carfax {
  cursor: pointer;
  object-fit: fill !important;
}

.car-listing-wide .product-carfax,
.car-listing-wide .product-accident-free,
.car-listing-wide .product-low-kilometers,
.car-listing-wide .product-top-condition,
.car-listing-wide .product-one-owner {
  width: 32% !important;
}

.product-carfax,
.product-accident-free,
.product-low-kilometers,
.product-top-condition,
.product-one-owner {
  /* width: 18.5%; */
  /* width: 23.5%;  */
  /* padding: 3px 0; */
  width: 24% !important;
}

.product-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0;
  margin: 0;
}

.page-link {
  color: var(--dark) !important;
}

.active>.page-link,
.page-link.active {
  background-color: var(--pink) !important;
  border-color: var(--pink) !important;
  color: var(--light) !important;
}

/*? CAR DETAIL STYLING */
.car-detail-container {
  max-width: 1200px;
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  /* font-family: 'Poppins', sans-serif; */
}

.car-detail-container div {
  z-index: 0;
}

.car-detail-container :first-child {
  z-index: 100;
}

.car-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.title-high-value {
  font-size: .96rem;
}

.data-section {
  scroll-margin-top: 180px;
}

.car-detail-header .navigation-buttons {
  margin-top: 7px;
}

.car-detail-header .navigation-buttons ul {
  margin-bottom: 0;
  padding-left: 0;
}

.car-detail-header .navigation-buttons ul li {
  margin-right: 20px;
  cursor: pointer;
  display: inline;
}

.car-detail-header .navigation-buttons ul li {
  margin-right: 20px;
  cursor: pointer;
}

.car-detail-header .navigation-buttons ul li:hover {
  color: var(--pink)
}

.car-detail-header .navigation-buttons ul li a {
  color: var(--black)
}

.car-detail-header .navigation-buttons ul li a:hover {
  color: var(--pink)
}

.VIN-listing-bolded {
  font-weight: 600;
  text-transform: uppercase;
}

.detail-title {
  font-weight: 500;
  font-size: 2rem;
  text-transform: uppercase;
}

.detail-specs {
  font-weight: 300;
  font-size: 1rem;
}

.product-detail {
  display: flex;
  gap: 20px;
  margin-bottom: 50px;
}

.car-detail-container .product-detail:nth-child(2) {
  height: 680px;
}

.product-detail-header {
  display: flex;
  justify-content: space-between;
}

.product-detail-price {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 5px;
  font-weight: 300;
  font-size: 1.5rem;
}

.detail-specifications {
  width: 40%;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
}

.detail-specifications .product-details {
  width: 100%;
  height: 100%;
  /* gap: 25px; */
}

.detail-specifications .product-financing-prg {
  font-size: 1.5rem;
}

.detail-specifications .taxes-and-licensing-fees {
  font-size: 1.2rem;
}

.detail-specifications .product-report-buttons button {
  width: 100%;
  gap: 10px;
}

.product-financing-calculator {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 60%;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  /* margin: 20px 0; */
}

.product-warrenty-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 40%;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}


.dealer-price,
.cost-of-borrowing,
.total-obligation,
.estimated-payment,
.sales-tax {
  display: flex;
  justify-content: space-between;
}

.financing-payment-title,
.financing-payment-title {
  font-size: 1rem;
  font-weight: 500;
  margin: 10px 0;
}

.total-obligation {
  border-bottom: 1px solid rgba(0, 0, 0, 0.150);
  padding-bottom: 10px;
  font-weight: 600;
}

.product-detail-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px !important;
}

.financing-button {
  width: 100%;
  margin: 15px 0;
}

.product-detail-buttons button {
  width: 100%;
}

.nav-link {
  color: var(--dark) !important;
}

.financing-disclaimer {
  font-size: .75rem;
  font-weight: 300;
}

/*? Vehicle Specs Styling */
.vehicle-details {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.vehicle-overview {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.vehicle-title {
  font-size: 1.2rem;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.car-detail-container .product-detail textarea {
  resize: none !important;
  border: none;
  outline: none;

  min-height: 300px;
}

.vehicle-specs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px 0;
  margin: 10px 0;
}

.vehicle-spec-title {
  font-size: .9rem;
  font-weight: 500;
}

.vehicle-spec-detail {
  font-size: .8rem;
  font-weight: 400;
}

.vehicle-overview-title {
  font-size: .95rem;
  font-weight: 500;
}

.vehicle-overview-detail {
  font-size: 1rem;
  font-weight: 300;
  text-align: right;

}

.vehicle-overview-specs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px 0;
  margin: 10px 0;
}

.vehicle-spec {
  display: flex;
  width: 45%;
  gap: 15px;
  align-items: center;
}

.vehicle-overview-spec {
  display: flex;
  width: 45%;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
}

/*? Vehicle Features */
.vehicle-feature-title {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 20px;
  /* padding: 10px 0; */
}

.vehicle-feature-specs {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.vehicle-feature-spec {
  /* background-color: var(--gray);
  color: var(--light); */
  display: flex;
  align-items: center;
  padding: 10px;
  width: fit-content;
  gap: 5px;

  font-size: .8rem;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  text-transform: capitalize !important;

  background-color: #e9ecef;
  border: 1px solid #dee2e6;
}

/*!!! Photo Gallery CSS Styling !!!*/
.detail-gallery {
  width: 60%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  /* border: 1px solid rgba(0,0,0,.125); */
  padding: 10px 0;
}

.gallery-tab {
  padding: 6px;
  /* margin-bottom: 10px; */
}

.active-tab {
  /* border: 2px solid rgba(0, 0, 0, 0.250);
  padding: 4px; */
  filter: brightness(0.9);
}

.tab-highlighted .gallery-tab {
  height: 100%;
}

.tab-highlighted .gallery-tab img {
  object-fit: cover;
  height: 100%;
}

.gallery-tab img {
  width: 100%;
  border-radius: 0px;
  object-fit: cover;
  user-select: none;
  /* transition: ease-in-out 150ms; */
}

.tab-highlighted {
  width: 100% !important;
  height: 75%;
  /* height: 420px; */
}

.tab-navigation {
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
}

.tab-navigation .gallery-tab {
  width: 25%;
  flex: 0 0 auto;
  display: flex;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: var(--gray);
}

::-webkit-scrollbar-thumb:hover {
  background: #999;
}

/* .year-select  select {
  width: 120px;
} */


/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
.add-listing-container {
  max-width: 1200px;
  width: 100%;
  margin: 50px auto;
  /* font-family: 'Poppins', sans-serif; */
}

.add-listing-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 25px 0;
  gap: 15px 0;
}

/* .add-listing-form .vehicle-overview-spec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-listing-form .vehicle-overview-detail {
  display: flex;
  align-items: center !important;
} */

.tier-system-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.addListing-tier-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.addListing-tier {
  width: 100px;
}

.addListing-tier img {
  width: 100%;
  height: 100%;
}

.add-listing-form .car-input {
  width: 100%;
}

.add-listing-form .car-input-status {
  margin: 15px 0;
  display: flex;
  width: 45%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.add-listing-form .car-input-status select {
  width: 225px !important;
  ;
}

.add-listing-form select {
  width: 230px !important;
}

.add-listing-form .publish-button {
  width: 100% !important;
}

.update-forward-container {
  width: 100%;
  display: flex;
  gap: 50px;
}

/*!!! AddListing Photo Gallery CSS Styling !!!*/
.bannerImages-gallery {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.gallery-tab {
  position: relative;
}

.gallery-tab .remove-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  color: var(--pink);
  background: white;
  border-radius: 5px;
  z-index: 1;
}

.gallery-tab .left-svg {
  left: 1%;
}

.right-svg,
.left-svg {
  top: 50%;
  position: absolute;
  color: white;
  cursor: pointer;
  user-select: none;
}

.gallery-tab .right-svg {
  right: 1%;
}

.gallery-tab .product-status {
  position: absolute;
  left: 15px;
  top: 15px;
  /* cursor: pointer;
  color: var(--pink);
  background: white;
  border-radius: 5px; */
  background: rgba(0, 0, 0, 0);
  z-index: 1;
}

.product-status {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 0px;
  width: fit-content;
  height: 40px;
  padding: 20px;
  color: var(--dark);
  font-weight: 500;
}

.available {
  color: var(--light);
  background-color: var(--forest) !important;
}

.pending {
  color: var(--light);
  background-color: orange !important;
}

.sold {
  color: var(--light);
  background-color: var(--pink) !important;
}

.Available,
.Pending,
.Sold {
  font-weight: 500;
}

.Available {
  color: var(--forest);
}

.Pending {
  color: orange
}

.Sold {
  color: var(--pink);
}

/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */

/*? FINANCING PAGE STYLING */
.financing-title,
.financing-description {
  margin-bottom: 20px;
}

.financing-title {
  font-size: 2rem;
  font-weight: 500;
}

.financing-description {
  font-size: 1rem;
  font-weight: 300;
}

/*? ERROR PAGE STYLING */
.error-container {
  max-width: 1200px;
  width: 100%;
  margin: 200px auto;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-code {
  font-size: 10rem;
  line-height: 1;
}

.error-message {
  font-size: 2rem;
  margin: 5px 0;
}

.error-description {
  font-size: 1rem;
  margin-bottom: 15px;
}

.spinner-container {
  width: 100%;
  max-width: 1200px;
  margin: 200px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-styling {
  width: 200px !important;
  height: 200px !important;
  font-size: 3rem !important;
  color: var(--gray);
}

.admin-login-container {
  width: 100%;
  max-width: 1200px;
  margin: 100px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-login-container form {
  max-width: 800px;
  width: 85%;
  display: flex;
  flex-direction: column;
  gap: 20px;

}

.modal-body {
  padding: 0 !important;
}

.img-modal {
  width: 100%;
  position: relative;
}

.img-modal img {
  width: 100%;
  user-select: none;
}

.img-modal svg {
  position: absolute;
  top: 0%;
  color: white;
  cursor: pointer;
  height: 100%;
}

.img-modal .left-svg {
  left: 1%;
}

.img-modal .right-svg {
  right: 1%;
}


.modal-backdrop.show {
  opacity: .88 !important;
}

.modal.show .modal-dialog {
  transform: none;
  margin: 0 auto;
  justify-content: center;
  width: 100%;
}

.modal-dialog {
  /* max-width: var(--bs-modal-width); */
  width: 85% !important;
}

.no-results-found {
  padding: 15px 20px;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  border-bottom: 1px solid rgba(0, 0, 0, .125);
}

/*! ABOUT US PAGE STYLING*/

.about-us-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* gap: 30px; */
  margin-top: 30px;
}

.about-us-child {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 48.5%;
  padding: 25px;
  margin: 12px 0;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  transition: 50ms ease-in-out;
  cursor: pointer;
}

.about-us-child:hover {
  background-color: #e9ecef;
}

/*! GRID VIEW FOR CAR INVENTORY */

.inventory-cars {
  border-left: 1px solid rgba(0, 0, 0, .125);
  border-right: 1px solid rgba(0, 0, 0, .125);
  /* border-bottom: 1px solid rgba(0,0,0,.125); */
  display: flex;
  flex-wrap: wrap;
}

.car-listing-grid-view {
  flex-grow: 1;
  max-width: 33.333333%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding: 15px 0; */
  border-bottom: 1px solid rgba(0, 0, 0, .125);
  /* height: 760px; */


  /* width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid rgba(0,0,0, .125); */
}

.car-listing-grid-view .product-img {
  width: 100%;
  height: 280px;
  padding: 15px 20px;
}

.car-listing-grid-view .product-details {
  width: 100%;
  padding: 0 20px;
  gap: 5px;
}

.car-listing-grid-view .product-report {
  width: 100%;
  padding: 10px 20px 20px 20px;
}


/*! BOOK APPOINTMENT STYLING */
.book-appointment-container {
  width: 1200px;
  margin: 0 auto;
  /* height: 800px; */
}

.book-appointment-form form {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 100px;
}

.booking-left {
  width: 50%;
}

.booking-right {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}

.time-slots-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 180px;
}

.time-slot {
  height: 40px;
  border: 1px solid #ff6358;
  color: #ff6358;
  background-color: var(--light)
}

.calander-container {
  width: 95%;
}

.calander-container-child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pagination-inventory {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.admin-navigation-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.appointment-details {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.coverage-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.coverage-header {
  font-size: .9rem;
}

.coverage-option {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, .200);
  padding-bottom: 15px;
}

.coverage-option-title {
  /* width: 20%; */
  font-size: 1.1rem;
  font-weight: 500;
}

.coverage-option-description {
  /* width: 80%; */
  font-size: .85rem;
}

.custom-warranty-button-container {
  display: flex;
  justify-content: space-between;
}

.warranty-option {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 20px;
  margin: 5px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.350);
  gap: 20px;
  flex-wrap: wrap;
}

.appointment-option {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 25px;
  margin: 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.350);
  gap: 20px;
}

@media print and (color) {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

@media print {

  nav,
  aside {
    display: none;
  }

  #printPageButton {
    display: none;
  }

  .footer,
  .summary-title,
  .warranty-progress-tracker {
    display: none !important;
  }

  body,
  article {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  @page {
    size: A3 landscape;
    margin: none;
  }
}

.accordion-button {
  background-color: transparent !important;
  color: #fff !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon) !important;
}

.accordion-button:focus {
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: none !important;
}

.warranty-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.warranty-progress-tracker {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding-bottom: 50px;
  color: #a9a9a9;
}

.warranty-progress-tracker div {
  font-size: 1.5rem;
  text-transform: capitalize;
  font-weight: 500;
}

.warranty-progress-selected {
  color: var(--dark);
}

.inquiry {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.inquiry-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 100px;
}


.spec-container .title {
  font-size: 17px;
  font-weight: 400;
  color: #000;
  word-spacing: 3px;
  letter-spacing: -0.3px;
  text-shadow: 1px 1px 1px #FFFFFF;
  text-align: left;
  background-color: #eaeaea;
  opacity: 0.60;
  width: 100%;
  padding: 10px 15px 10px 15px;
  margin: 10px 0px;
}

div.spec-container div.group:has(div.vehicle-feature-specs:empty,div.sheet:empty) {
  display: none;
}

.spec-container .prop {
  border-bottom: 1px solid #ededed;
  padding: 10px 20px;
  vertical-align: top;
  font-size: medium;
}

.spec-container .prop:nth-child(odd) {
  padding-right: 20px;
}

.spec-container .label {
  font-weight: 500;
  flex: 1 1 0;

}

.spec-container .value-container {
  flex: 1 1 0;

}

.spec-container .value-container:empty {
  flex: 0;
}

.spec-container .value:not(:last-child) {
  border-bottom: 1px solid #ededed
    /* text-align: right; */
}

/* .spec-container .prop .value-container{
  outline: 1px solid;
} */
/* .spec-container .prop .value-container:has(:nth-child(2)):not(:has(input:checked)){ */


small.vin-error {
  color: var(--pink);
}

.gather-data .error {
  color: var(--pink);
  padding-top: 5px;
  padding-bottom: 15px;
  text-align: center;
}

.gather-data select {
  width: 120px;
}

.bold {
  font-weight: bold;
}

.semi-bold {
  font-weight: 500;
}

#vehicle-data-section{
  font-size: .91rem;
}

#vehicle-data-section .flex-row {
  padding: 5px 0 5px 0;
}

#vehicle-data-section .flex-row .key {
  flex: 1 1 0;
  color: #6f6f6f
}

#vehicle-data-section .flex-row .value {
  flex: 1 1 0;
  font-weight: 500;
  padding-left: 5px;
}

#vehicle-data-section .vehicle-title{
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 1.1rem;
}

.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
}

.react-tagsinput--focused {
  border-color: #a5d24a;
}

.react-tagsinput-tag {
  /* background-color: #cde69c;
  border-radius: 2px;
  border: 1px solid #a5d24a;
  color: #638421; */
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
  background-color: rgb(218, 216, 216);
  display: inline-block;
  padding: 0.5em 0.75em;
  border-radius: 20px;
  font-weight: bold;
  color: #000;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
  height: 16px;
  width: 16px;
  background-color: rgb(48, 48, 48);
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: .5em;
  font-size: 14px;
  cursor: pointer;
}

.react-tagsinput-tag a::before {
  content: " ×";
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 150px;
}


.cursor-pointer{
  cursor: pointer;
}